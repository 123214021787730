import AndroidStore from '../view/androidStore.vue'
import HomeView from '@/view/homeView.vue'
import OfferView from '@/view/offerView.vue'
import PreloaderView from '@/view/preloaderView.vue'

import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
const routes = [
	{
		path: '/',
		component: () => import('../view/preloaderView')
	},

	{
		path: '/store',
		name: 'store',
		component: () => import('../view/androidStore')
	},


	
	{
		path: '/game',
		name: 'home',
		component: HomeView,
	},
	{
		path: '/offer',
		name: 'offer',
		component: OfferView,
	},

]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

export default router
