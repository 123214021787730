<template>
  <router-view />
  <a href="" ref="gotochrome" style="display: none"></a>
</template>

<script setup>
import { useRouter } from "vue-router";
import { onMounted, ref, onBeforeMount, onUnmounted } from "vue";
import { mainStore } from "@/store/store.js";

const store = mainStore();
const gotochrome = ref();
const browserName = ref();
const userDevice = ref();
const router = useRouter();
const nAgt = navigator.userAgent;
const getUserBrowserName = () => {
  if (nAgt.indexOf("YaBrowser") !== -1) {
    return "YaBrowser";
  } else if (nAgt.indexOf("Opera") !== -1) {
    return "Opera";
  } else if (nAgt.indexOf("MSIE") !== -1) {
    return "MSIE";
  } else if (nAgt.indexOf("Chrome") !== -1) {
    return "Chrome";
  } else if (nAgt.indexOf("Safari") !== -1) {
    return "Safari";
  } else if (nAgt.indexOf("Firefox") !== -1) {
    return "Firefox";
  } else {
    return "error";
  }
};

const getUserDevice = () => {
  if (nAgt.indexOf("Android") !== -1) {
    return "Android";
  } else {
    return "IOS";
  }
};

userDevice.value = getUserDevice();
browserName.value = getUserBrowserName();

onBeforeMount(() => {
  if (getUserDevice() != "Android") router.replace("/offer");
  store.beforeinstallprompt();
});

onMounted(() => {
  const router = useRouter();
  const isPwa = store.checkDisplay();
  const isLink = localStorage.getItem("link");
  const link = "https://ida.wake-app.net/xFjCt6";
  const params = new URLSearchParams(document.location.search);
  if (browserName.value != "Chrome" && userDevice.value == "Android") {
    gotochrome.value.setAttribute(
      "href",
      `intent://navigate?url=${window.location.hostname}/${params}#Intent;scheme=googlechrome;end;`
    );
    gotochrome.value.click();
  }

  if (!localStorage.getItem("params")) {
    localStorage.setItem(
      "params",
      window.location.search.replace("?openLand=true", "")
    );
  }

  const showOffer = () => {
    router.push("/offer");
  };

  const showGame = () => {
    router.push("/game");
  };

  const showStore = () => {
    router.push("/store");
  };

  const startApp = () => {
    if (!localStorage.getItem("link")) {
      const offer_link = link + localStorage.getItem("params");
      localStorage.setItem("link", offer_link);
    }

    if (
      localStorage.getItem("showOffer") ||
      (isPwa && store.installed && isLink)
    ) {
      if (isPwa) {
        if (isLink) {
          if (isLink.indexOf("openApp") < 0) {
            const newLink = isLink
              .replace("?sub_id_11=autoOpen", "")
              .replace("&sub_id_11=autoOpen", "")
              .replace("?sub_id_11=openWeb", "")
              .replace("&sub_id_11=openWeb", "")
              .replace("?sub_id_11=openLandWeb", "")
              .replace("&sub_id_11=openLandWeb", "");

            const checkQuery = newLink.indexOf("?");
            const symbol = checkQuery < 0 ? "?" : "&";

            localStorage.setItem(
              "link",
              newLink + symbol + "sub_id_11=openApp"
            );
          }
        }
      } else {
        if (isLink) {
          if (isLink.indexOf("openWeb") < 0) {
            const newLink = isLink
              .replace("?sub_id_11=autoOpen", "")
              .replace("&sub_id_11=autoOpen", "")
              .replace("?sub_id_11=openApp", "")
              .replace("&sub_id_11=openApp", "")
              .replace("?sub_id_11=openLandWeb", "")
              .replace("&sub_id_11=openLandWeb", "");

            const checkQuery = newLink.indexOf("?");
            const symbol = checkQuery < 0 ? "?" : "&";

            localStorage.setItem(
              "link",
              newLink + symbol + "sub_id_11=openWeb"
            );
          }
        }
      }

      showOffer();
    } else if (!isPwa) {
      if (params.get("redirect")) {
        localStorage.setItem("openStore", true);
      }

      if (params.get("openLand")) {
        showGame();
      } else {
        showStore();
      }
    } else {
      params.get("redirect");
      if (params.get("redirect")) {
        localStorage.setItem("openStore", true);
      }
      showStore();
    }
  };

  startApp();
});
</script>
<style lang="scss" scoped></style>
