import OneSignalVuePlugin from "@onesignal/onesignal-vue3";
import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./routes";
import "./style.scss";

const pinia = createPinia();
const app = createApp(App);

app
  .use(pinia)
  .use(router)
  .mount("#app");



