<script setup>
import { ref, watch, onMounted, onBeforeMount, computed } from "vue";
import { mainStore } from "@/store/store.js";
import AndroidStore from "@/view/androidStore.vue";

import { useRouter } from "vue-router";
const nAgt = navigator.userAgent;
const router = useRouter();
const wheelButtonRef = ref();
const wheelRef = ref();
const link = ref();
const popupRef = ref();
const popupWindow1Ref = ref();
const popupWindow2Ref = ref();
const bonusesPageRef = ref();
const popupButtonRef = ref();
const gameWheelRef = ref();
const gameCardRef = ref();
const card1Ref = ref();
const card2Ref = ref();
const card3Ref = ref();
const bonus2Ref = ref();
const cardOpenLength = ref(0);
const loading = ref(false);
const store = mainStore();
const installBtn = ref();
const spinWheel = () => {
  wheelRef.value.classList.remove("wheel__spinner_animated");
  wheelRef.value.classList.add("wheel__spinner_win");
  setTimeout(() => {
    // localStorage.setItem("popup_show", true);
    // popupRef.value.classList.add("popup__show");
    // popupWindow1Ref.value.classList.add("popup__window_show");
    // bonusesPageRef.value.classList.remove("bonuses__hidden");

    localStorage.setItem("end_game", true);
    popupRef.value.classList.add("popup__show"),
      popupWindow2Ref.value.classList.add("popup__window_show"),
      bonus2Ref.value.classList.remove("bonus__hidden");
  }, 4e3);
};

const clickContinue = () => {
  localStorage.setItem("game_show", true);
  popupRef.value.classList.remove("popup__show");
  popupWindow1Ref.value.classList.remove("popup__window_show");
  gameWheelRef.value.classList.add("game__hidden");
  gameCardRef.value.classList.remove("game__hidden");
};

const clickCard1 = () => {
  localStorage.setItem("card_open1", true);
  card1Ref.value.classList.add("card__animation");
  card1Ref.value.children[4].src =
    "https://hilennodinydasy.com/promo/6009/img" + "/card-animation.gif?a=1";
  cardOpenLength.value++;
};
const clickCard2 = () => {
  localStorage.setItem("card_open2", true);
  card2Ref.value.classList.add("card__animation");
  card2Ref.value.children[4].src =
    "https://hilennodinydasy.com/promo/6009/img" + "/card-animation.gif?a=2";
  cardOpenLength.value++;
};
const clickCard3 = () => {
  localStorage.setItem("card_open3", true);
  card3Ref.value.classList.add("card__animation");
  card3Ref.value.children[4].src =
    "https://hilennodinydasy.com/promo/6009/img" + "/card-animation.gif?a=3";
  cardOpenLength.value++;
};

watch(cardOpenLength, (newValue) => {
  if (newValue == 2) {
    card1Ref.value.classList.add("card__disabled");
    card2Ref.value.classList.add("card__disabled");
    card3Ref.value.classList.add("card__disabled");
    setTimeout(async function () {
      localStorage.setItem("end_game", true);
      popupRef.value.classList.add("popup__show"),
        popupWindow2Ref.value.classList.add("popup__window_show"),
        bonus2Ref.value.classList.remove("bonus__hidden");
    }, 2e3);
  }
});

const getUserDevice = () => {
  if (nAgt.indexOf("Android") !== -1) {
    return "Android";
  } else {
    return "IOS";
  }
};

onMounted(() => {
  if (localStorage.getItem("popup_show")) {
    popupRef.value.classList.add("popup__show"),
      popupWindow2Ref.value.classList.add("popup__window_show"),
      bonus2Ref.value.classList.remove("bonus__hidden");

    // popupRef.value.classList.add("popup__show");
    // popupWindow1Ref.value.classList.add("popup__window_show");
    // bonusesPageRef.value.classList.remove("bonuses__hidden");
  }

  if (localStorage.getItem("game_show")) {
    popupRef.value.classList.remove("popup__show");
    popupWindow1Ref.value.classList.remove("popup__window_show");
    gameWheelRef.value.classList.add("game__hidden");
    gameCardRef.value.classList.remove("game__hidden");
  }

  if (localStorage.getItem("end_game")) {
    popupRef.value.classList.add("popup__show"),
      popupWindow2Ref.value.classList.add("popup__window_show"),
      bonus2Ref.value.classList.remove("bonus__hidden");
  }

  if (localStorage.getItem("card_open1")) {
    card1Ref.value.classList.add("card__animation");
    card1Ref.value.children[4].src =
      "https://hilennodinydasy.com/promo/6009/img" + "/card-animation.gif?a=1";
    cardOpenLength.value++;
  }

  if (localStorage.getItem("card_open2")) {
    card2Ref.value.classList.add("card__animation");
    card2Ref.value.children[4].src =
      "https://hilennodinydasy.com/promo/6009/img" + "/card-animation.gif?a=2";
    cardOpenLength.value++;
  }
  if (localStorage.getItem("card_open3")) {
    card3Ref.value.classList.add("card__animation");
    card3Ref.value.children[4].src =
      "https://hilennodinydasy.com/promo/6009/img" + "/card-animation.gif?a=3";
    cardOpenLength.value++;
  }
  if (localStorage.getItem("openStore")) {
    const wrapper = document.querySelector(".wrapper");
    wrapper.classList.add("hide");
    const html = document.querySelector("html");

    html.classList.add("px16");
    store.showStore = true;

    //router.replace("/store");
  }
});

const showStore = () => {
  localStorage.setItem("openStore", true);
  localStorage.setItem("showOffer", true);
  const isLink = localStorage.getItem("link");

  if (isLink.indexOf("openWeb") < 0) {
    const newLink = isLink
      .replace("?sub_id_11=autoOpen", "")
      .replace("&sub_id_11=autoOpen", "")
      .replace("?sub_id_11=openApp", "")
      .replace("&sub_id_11=openApp", "")
      .replace("?sub_id_11=openWeb", "")
      .replace("&sub_id_11=openWeb", "");

    const checkQuery = newLink.indexOf("?");
    const symbol = checkQuery < 0 ? "?" : "&";

    localStorage.setItem("link", newLink + symbol + "sub_id_11=openLandWeb");
  }
  router.replace("/offer");
};
</script>

<template>
  <div class="wrapper">
    <div class="container">
      <div
        ref="bonusesPageRef"
        class="bonuses bonuses__hidden bonuses__page"
        id="bonuses-page"
      >
        <div class="bonus bonus_1">
          <div class="bonus__title">
            <div class="bonus__title_icon bonus__title_respin">
              <img src="../assets/respin.svg" alt="" />
            </div>
            <div class="bonus__title_text">
              <span class="en">FS:</span>
            </div>
          </div>
          <div class="bonus__sum">
            <span>250</span>
          </div>
        </div>
        <div ref="bonus2Ref" class="bonus bonus__hidden bonus_2" id="bonus-2">
          <div class="bonus__title">
            <div class="bonus__title_icon bonus__title_money">
              <img src="../assets/money.svg" alt="" />
            </div>
            <div class="bonus__title_text">
              <span class="en">$:</span>
            </div>
          </div>
          <div class="bonus__sum">
            <span class="en">450</span>
          </div>
        </div>
      </div>
      <div ref="gameWheelRef" class="game" id="game__wheel">
        <div class="title">
          <div class="title__additional">
            <span class="en">SPIN THE WHEEL and</span>
          </div>
          <div class="title__main title__main_first">
            <span class="en">GET FREE SPINS</span>
          </div>
        </div>
        <div class="wheel">
          <div class="wheel__around"></div>
          <div class="wheel__arrow"></div>
          <div class="wheel__bottom"></div>
          <img
            ref="wheelRef"
            class="wheel__spinner wheel__spinner_animated"
            id="wheel__spinner"
            data-path="https://hilennodinydasy.com/promo/6009/img"
            src="../assets/wheel-en.png"
          />
          <div
            ref="wheelButtonRef"
            @click="spinWheel"
            class="wheel__button"
            id="wheel__button"
          >
            <span class="en">SPIN</span>
          </div>
        </div>
      </div>
      <div ref="gameCardRef" class="game game__hidden" id="game__cards">
        <div class="title title__second">
          <div class="title__additional">
            <span class="en">SCRATCH THE CARDS AND</span>
          </div>
          <div class="title__main">
            <span class="en">GET YOUR BONUS</span>
          </div>
        </div>
        <div class="cards">
          <div
            @click="clickCard1"
            ref="card1Ref"
            class="card"
            data-attr="card1"
          >
            <div class="card__normal">
              <img src="../assets/card.png" alt="" />
            </div>
            <div class="card__final">
              <img src="../assets/card__win.png" alt="" />
            </div>
            <div class="card__sum">
              <img class="en" src="../assets/money-euro.jpg" alt="" />
            </div>
            <div class="card__bonus">
              <span class="en">x6</span>
            </div>
            <img
              class="card__action"
              src="../assets/card-animation.gif"
              alt=""
            />
          </div>
          <div
            @click="clickCard2"
            ref="card2Ref"
            class="card"
            data-attr="card2"
          >
            <div class="card__normal">
              <img src="../assets/card.png" alt="" />
            </div>
            <div class="card__final">
              <img src="../assets/card__win.png" alt="" />
            </div>
            <div class="card__sum">
              <img class="en" src="../assets/money-euro.jpg" alt="" />
            </div>
            <div class="card__bonus">
              <span class="en">x6</span>
            </div>
            <img
              class="card__action"
              src="../assets/card-animation.gif"
              alt=""
            />
          </div>
          <div
            @click="clickCard3"
            ref="card3Ref"
            class="card"
            data-attr="card3"
          >
            <div class="card__normal">
              <img src="../assets/card.png" alt="" />
            </div>
            <div class="card__final">
              <img src="../assets/card__win.png" alt="" />
            </div>
            <div class="card__sum">
              <img class="en" src="../assets/money-euro.jpg" alt="" />
            </div>
            <div class="card__bonus">
              <span class="en">x6</span>
            </div>
            <img
              class="card__action"
              src="../assets/card-animation.gif"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div ref="popupRef" class="popup" id="popup">
      <div ref="popupWindow1Ref" class="popup__window" id="popup__window_1">
        <div class="popup__element popup__element_heart">
          <img src="../assets/popup-heart.png" alt="" />
        </div>
        <div class="popup__element popup__element_money">
          <img src="../assets/popup-money.png" alt="" />
        </div>
        <div class="popup__element popup__element_cube">
          <img src="../assets/popup-cube.png" alt="" />
        </div>
        <div class="popup__content">
          <div class="popup__title">
            <span class="en">YOU WON</span>
          </div>
          <div class="popup__win popup__win_first">
            <span class="en">250 FREE SPINS</span>
          </div>
          <div class="popup__img">
            <img src="../assets/candy.png" alt="" />
          </div>
          <button
            @click="clickContinue"
            class="popup__button"
            id="popup__button"
          >
            <span class="en">CONTINUE</span>
          </button>
        </div>
      </div>
      <div ref="popupWindow2Ref" class="popup__window" id="popup__window_2">
        <div class="popup__element popup__element_heart">
          <img src="../assets/popup-heart.png" alt="" />
        </div>
        <div class="popup__element popup__element_money">
          <img src="../assets/popup-money.png" alt="" />
        </div>
        <div class="popup__element popup__element_cube">
          <img src="../assets/popup-cube.png" alt="" />
        </div>
        <div class="popup__content">
          <div class="popup__title popup__title_final">
            <span class="en"
              >PICK UP YOUR<br />
              WELCOME BONUS</span
            >
          </div>
          <div class="popup__win popup__win_final">
            <span class="en">450 $ + <br />250 FREE SPINS</span>
          </div>
          <div class="bonuses popup__bonuses">
            <div class="bonus bonus_1">
              <div class="bonus__title">
                <div class="bonus__title_icon bonus__title_respin">
                  <img src="../assets/respin.svg" alt="" />
                </div>
                <div class="bonus__title_text">
                  <span class="en">FS:</span>
                </div>
              </div>
              <div class="bonus__sum">
                <span>250</span>
              </div>
            </div>
            <div class="bonus bonus_2">
              <div class="bonus__title">
                <div class="bonus__title_icon bonus__title_money">
                  <img src="../assets/money.svg" alt="" />
                </div>
                <div class="bonus__title_text">
                  <span class="en">$:</span>
                </div>
              </div>
              <div class="bonus__sum">
                <span class="en">450</span>
              </div>
            </div>
          </div>
          <a
            href="#"
            ref="installBtn"
            @click="showStore"
            class="popup__button popup__button_final"
          >
            <span class="en z-10000">GET BONUS</span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- <AndroidStore
    v-if="store.showStore"
    :class="[store.showStore ? 'show' : '']"
  ></AndroidStore> -->
</template>

<style scoped>
.wrapper {
  background: url(../assets/bg.jpg) 50% 0 no-repeat no-repeat;
  max-width: 192rem;
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-size: cover;
}
.container {
  max-width: 134rem;
  margin: 0 auto;
  padding: 4rem 2rem 2rem;
  position: relative;
}
html.en .en {
  display: block;
}
html .en {
  display: block;
}
html.de .de {
  display: block;
}
html .de {
  display: none;
}
html.pl .pl {
  display: block;
}
html .pl {
  display: none;
}
html.ru .ru {
  display: block;
}
html .ru {
  display: none;
}
html.no .no {
  display: block;
}
html .no {
  display: none;
}
html.cz .cz {
  display: block;
}
html .cz {
  display: none;
}
html.ro .ro {
  display: block;
}
html .ro {
  display: none;
}
html.ee .ee {
  display: block;
}
html .ee {
  display: none;
}
html.lt .lt {
  display: block;
}
html .lt {
  display: none;
}
html.lv .lv {
  display: block;
}
html .lv {
  display: none;
}
html.gr .gr {
  display: block;
}
html .gr {
  display: none;
}
html.hu .hu {
  display: block;
}
html .hu {
  display: none;
}
html.dk .dk {
  display: block;
}
html .dk {
  display: none;
}
html.it .it {
  display: block;
}
html .it {
  display: none;
}
html.fr .fr {
  display: block;
}
html .fr {
  display: none;
}
.lang_switcher_outer {
  position: absolute;
  top: 4rem;
  right: 2rem;
  width: 15em;
  height: 4em;
  z-index: 9;
  cursor: pointer;
  color: #000050;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 100%;
}
.lang_switcher_outer .lang_switcher {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  display: block;
  cursor: pointer;
  position: relative;
  z-index: 3;
  background: #febcfc;
  border-radius: 0.5em;
  height: 4em;
}
.lang_switcher_outer .lang_switcher .curr_lang {
  position: relative;
  display: inline-block;
  top: 0.8em;
  margin: 0 0.5em 0 0.6em;
}
.lang_switcher_outer .lang_switcher .curr_lang span {
  font-size: 1.3em;
  padding: 0.35em 0 0 0;
  display: inline-block;
}
.lang_switcher_outer .lang_switcher .curr_lang .lang_flag {
  top: 0.4em;
  margin: 0 0.5em 0 0;
}
.lang_switcher_outer i {
  top: 0.8em;
  background: url(../assets/lang-arr.svg) 0 0 no-repeat;
  background-size: contain;
  width: 1.4em;
  position: relative;
  height: 0.8em;
  display: inline-block;
}
.lang_switcher_outer p {
  font-size: 1.1em;
  position: relative;
  color: #000050;
  top: 0.7em;
  text-transform: none;
  display: inline-block;
  width: 42%;
  text-align: center;
  margin: 0 0 0 0.3em;
  white-space: nowrap;
}
.flag_ru {
  opacity: 0;
}
.lang_flag {
  display: inline-block;
  border-radius: 50%;
  width: 1.6em;
  height: 1.6em;
  margin: 0 0.7em 0 0.5em;
  position: relative;
  top: 0.35em;
  overflow: hidden;
}
.lang_list {
  position: absolute;
  list-style-type: none;
  top: 3em;
  display: none;
  right: 0;
  padding: 1em 0 0 0;
  width: 8.5em;
  overflow: hidden;
  cursor: auto;
  border-top: none;
  background: rgba(254, 188, 252, 0.9);
  border-radius: 0 0 0.5em 0.5em;
}
.lang_list.act {
  display: grid;
  grid-template-columns: 1fr;
}
.lang_list li {
  background: 0 0;
  height: 3em;
  cursor: pointer;
  padding: 0 0.5em;
}
.lang_list li span {
  font-size: 1.3em;
  padding: 0.7em 0 0 0;
  display: inline-block;
}
.lang_list li.curr {
  pointer-events: none;
  background: #fff;
}
.lang_list li.curr span {
  color: #02569f;
}
.lang_list li:hover {
  background: #fff;
}
.lang_list li:hover span {
  color: #02569f;
}
.logo {
  max-width: 48rem;
  min-width: 42rem;
  display: block;
  margin: 0 auto 2rem;
  position: relative;
  z-index: 15;
}
.title {
  margin-bottom: 5.4rem;
}
.title__second {
  margin-bottom: 15.4rem;
}
.title__additional {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #fff;
}
.title__main {
  font-weight: 900;
  font-size: 7.2rem;
  line-height: 1.15;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #f7e936;
  text-shadow: 0 -0.4rem 0 #991b80, 0 -0.4rem 0 #991b80, 0 0.4rem 0 #991b80,
    0 0.4rem 0 #991b80, -0.4rem 0 0 #991b80, 0.4rem 0 0 #991b80,
    -0.4rem 0 0 #991b80, 0.4rem 0 0 #991b80, -0.1rem -0.4rem 0 #991b80,
    0.1rem -0.4rem 0 #991b80, -0.1rem 0.4rem 0 #991b80, 0.1rem 0.4rem 0 #991b80,
    -0.4rem -0.1rem 0 #991b80, 0.4rem -0.1rem 0 #991b80,
    -0.4rem 0.1rem 0 #991b80, 0.4rem 0.1rem 0 #991b80, -0.2rem -0.4rem 0 #991b80,
    0.2rem -0.4rem 0 #991b80, -0.2rem 0.4rem 0 #991b80, 0.2rem 0.4rem 0 #991b80,
    -0.4rem -0.2rem 0 #991b80, 0.4rem -0.2rem 0 #991b80,
    -0.4rem 0.2rem 0 #991b80, 0.4rem 0.2rem 0 #991b80, -0.3rem -0.4rem 0 #991b80,
    0.3rem -0.4rem 0 #991b80, -0.3rem 0.4rem 0 #991b80, 0.3rem 0.4rem 0 #991b80,
    -0.4rem -0.3rem 0 #991b80, 0.4rem -0.3rem 0 #991b80,
    -0.4rem 0.3rem 0 #991b80, 0.4rem 0.3rem 0 #991b80, -0.4rem -0.4rem 0 #991b80,
    0.4rem -0.4rem 0 #991b80, -0.4rem 0.4rem 0 #991b80, 0.4rem 0.4rem 0 #991b80,
    -0.4rem -0.4rem 0 #991b80, 0.4rem -0.4rem 0 #991b80,
    -0.4rem 0.4rem 0 #991b80, 0.4rem 0.4rem 0 #991b80;
}
.gr .title__main,
.hu .title__main,
.lt .title__main,
.lv .title__main {
  font-size: 5.2rem;
}
.wheel {
  position: relative;
  width: max-content;
  margin: 0 auto 42rem;
}
.wheel__spinner {
  width: 44.3em;
  height: 44.3em;
  position: relative;
  z-index: 2;
}
.wheel__around {
  background: url(../assets/wheel-around.png) 50% 50%;
  background-size: 100% 100%;
  position: absolute;
  pointer-events: none;
  left: -10.2em;
  top: -8%;
  width: 58.1em;
  height: 59.1em;
  z-index: 3;
}
.wheel__arrow {
  background: url(../assets/wheel-arrow.png);
  width: 10em;
  height: 8.6em;
  background-size: 100% 100%;
  position: absolute;
  pointer-events: none;
  z-index: 4;
  left: 50%;
  top: -8%;
  transform: translateX(-50%);
}
.wheel__bottom {
  position: absolute;
  background: url(../assets/wheel-bottom.png) 50% 50%;
  background-size: 100% 100%;
  width: 4.3em;
  height: 29.9em;
  z-index: 1;
  left: 45%;
  transform: translate(-50%, 100%);
  bottom: 40%;
}
.wheel__button {
  width: 12em;
  height: 12em;
  border-radius: 50%;
  background: #ff8c02;
  background: radial-gradient(circle, #ff8c02 0, #f74701 100%);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  border: none;
  outline: 0;
  cursor: pointer;
  box-shadow: 0 0 3em #d7b7c1;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wheel__button:disabled {
  pointer-events: none;
  box-shadow: 0 0 4em #f8cada;
}
.wheel__button:hover {
  box-shadow: 0 0 4em #f8cada;
}
.wheel__button span {
  font-style: italic;
  font-weight: 900;
  font-size: 3.2em;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  z-index: 6;
}
.gr .wheel__button span,
.lv .wheel__button span,
.pl .wheel__button span,
.ru .wheel__button span {
  font-size: 2.4em;
}
.cz .wheel__button span,
.ee .wheel__button span,
.fr .wheel__button span,
.hu .wheel__button span,
.ro .wheel__button span {
  font-size: 2em;
}
.wheel__spinner_animated {
  animation: 2s spinner ease-in-out infinite;
}
.wheel__spinner_win {
  animation: 3s spinTo1 ease-in-out forwards;
}
.wheel__spinner_final {
  transform: rotate(720deg);
}
@keyframes spinner {
  0% {
    transform: rotate(-40deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(-40deg);
  }
}
@keyframes spinTo1 {
  0% {
    transform: rotate(-40deg);
  }
  70% {
    transform: rotate(724deg);
  }
  80% {
    transform: rotate(716deg);
  }
  100% {
    transform: rotate(720deg);
  }
}
.payments {
  margin: 0 auto 2rem;
}
.payments__desk {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
.payments__mob {
  display: none;
}
.footer__text {
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
}
.footer__text p {
  margin-bottom: 1.2rem;
}
.footer__text p:last-child {
  margin-bottom: 0;
}
.popup {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(1, 0, 80, 0.8);
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}
.popup__show {
  opacity: 1;
  pointer-events: all;
}
.popup__window {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 14rem;
  background: url(../assets/popup-window.png) 50% 50%;
  background-size: 100% 100%;
  width: 61.7em;
  padding: 8em 4em 4em;
  display: none;
}
.popup__window_show {
  display: block;
}
.popup__title span {
  font-weight: 900;
  font-size: 5.4em;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #fff;
}
.popup__title_final span {
  font-size: 4em !important;
}
.popup__win {
  margin-bottom: 4.6em;
}
.popup__win_final {
  margin-bottom: 3.8em;
}
.popup__win span {
  font-weight: 900;
  font-size: 5.4em;
  line-height: 1.15;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #f7e936;
  text-shadow: 0 -0.3rem 0 #8c1c75, 0 -0.3rem 0 #8c1c75, 0 0.3rem 0 #8c1c75,
    0 0.3rem 0 #8c1c75, -0.3rem 0 0 #8c1c75, 0.3rem 0 0 #8c1c75,
    -0.3rem 0 0 #8c1c75, 0.3rem 0 0 #8c1c75, -0.1rem -0.3rem 0 #8c1c75,
    0.1rem -0.3rem 0 #8c1c75, -0.1rem 0.3rem 0 #8c1c75, 0.1rem 0.3rem 0 #8c1c75,
    -0.3rem -0.1rem 0 #8c1c75, 0.3rem -0.1rem 0 #8c1c75,
    -0.3rem 0.1rem 0 #8c1c75, 0.3rem 0.1rem 0 #8c1c75, -0.2rem -0.3rem 0 #8c1c75,
    0.2rem -0.3rem 0 #8c1c75, -0.2rem 0.3rem 0 #8c1c75, 0.2rem 0.3rem 0 #8c1c75,
    -0.3rem -0.2rem 0 #8c1c75, 0.3rem -0.2rem 0 #8c1c75,
    -0.3rem 0.2rem 0 #8c1c75, 0.3rem 0.2rem 0 #8c1c75, -0.3rem -0.3rem 0 #8c1c75,
    0.3rem -0.3rem 0 #8c1c75, -0.3rem 0.3rem 0 #8c1c75, 0.3rem 0.3rem 0 #8c1c75,
    -0.3rem -0.3rem 0 #8c1c75, 0.3rem -0.3rem 0 #8c1c75,
    -0.3rem 0.3rem 0 #8c1c75, 0.3rem 0.3rem 0 #8c1c75;
}
.popup__img {
  margin: 0 auto 2.6em;
  display: block;
  width: 21.8em;
}
.popup__button {
  background: url(../assets/popup-btn.png) 50% 50%;
  background-size: 100% 100%;
  width: 50.7em;
  height: 9.6em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: 0;
  margin: 0 auto;
  cursor: pointer;
  font-size: inherit;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
}
.popup__button span {
  font-weight: 900;
  font-size: 2.7em;
  text-align: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #fff;
  text-shadow: 0 4px 4px rgba(152, 0, 136, 0.7);
}
.popup__button:hover {
  filter: drop-shadow(0 0 2rem #ff1693);
}
.popup__element {
  pointer-events: none;
  position: absolute;
}
.popup__element_heart {
  left: 4%;
  top: 0;
  width: 8em;
}
.popup__element_cube {
  right: -8%;
  top: 40%;
  width: 11em;
}
.popup__element_money {
  width: 14.9em;
  left: -10%;
  bottom: 20%;
}
.hu .popup__win_final span {
  font-size: 4.5em;
}
.lt .popup__win_final span {
  font-size: 5em;
}
.pl .popup__title span {
  font-size: 4.2em;
}
.pl .popup__title_final span {
  font-size: 4em;
}
.pl .fs {
  font-size: 0.7em;
  line-height: 1.15;
  display: block;
}
.pl .popup__win_first span {
  font-size: 4.8em;
}
.de .popup__title span {
  font-size: 5.2em;
}
.de .popup__win_first span {
  font-size: 5.2em;
}
.de .popup__button_final span {
  font-size: 2.4em;
}
.bonuses {
  display: grid;
  grid-template-columns: max-content;
  grid-gap: 1em;
  justify-content: center;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
  z-index: 3;
}
.bonuses__hidden {
  opacity: 0;
}
.bonuses__page {
  position: absolute;
  left: 0;
  transform: translateX(-40%);
  top: 13rem;
}
.bonus {
  width: 17.5em;
  height: 20.4em;
  background: url(../assets/bonus-bg.png) 50% 50%;
  background-size: 100% 100%;
  padding: 4em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
}
.bonus__hidden {
  opacity: 0;
}
.bonus__title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.8em;
}
.bonus__title_icon {
  margin-right: 0.5em;
}
.bonus__title_respin {
  width: 3.8em;
  height: 3.8em;
}
.bonus__title_money {
  width: 4.4em;
  height: 2.7em;
}
.bonus__title_text span {
  font-weight: 700;
  font-size: 3em;
  color: #fff;
  text-align: center;
}
.bonus__sum {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bonus__sum span {
  font-weight: 700;
  font-size: 4.9em;
  line-height: 1.5;
  color: #f7e936;
  text-shadow: 0 -0.3rem 0 #8c1c75, 0 -0.3rem 0 #8c1c75, 0 0.3rem 0 #8c1c75,
    0 0.3rem 0 #8c1c75, -0.3rem 0 0 #8c1c75, 0.3rem 0 0 #8c1c75,
    -0.3rem 0 0 #8c1c75, 0.3rem 0 0 #8c1c75, -0.1rem -0.3rem 0 #8c1c75,
    0.1rem -0.3rem 0 #8c1c75, -0.1rem 0.3rem 0 #8c1c75, 0.1rem 0.3rem 0 #8c1c75,
    -0.3rem -0.1rem 0 #8c1c75, 0.3rem -0.1rem 0 #8c1c75,
    -0.3rem 0.1rem 0 #8c1c75, 0.3rem 0.1rem 0 #8c1c75, -0.2rem -0.3rem 0 #8c1c75,
    0.2rem -0.3rem 0 #8c1c75, -0.2rem 0.3rem 0 #8c1c75, 0.2rem 0.3rem 0 #8c1c75,
    -0.3rem -0.2rem 0 #8c1c75, 0.3rem -0.2rem 0 #8c1c75,
    -0.3rem 0.2rem 0 #8c1c75, 0.3rem 0.2rem 0 #8c1c75, -0.3rem -0.3rem 0 #8c1c75,
    0.3rem -0.3rem 0 #8c1c75, -0.3rem 0.3rem 0 #8c1c75, 0.3rem 0.3rem 0 #8c1c75,
    -0.3rem -0.3rem 0 #8c1c75, 0.3rem -0.3rem 0 #8c1c75,
    -0.3rem 0.3rem 0 #8c1c75, 0.3rem 0.3rem 0 #8c1c75;
}
.cz .bonus_2 .bonus__sum span,
.no .bonus_2 .bonus__sum span {
  font-size: 4em;
}
.hu .bonus_2 .bonus__sum span {
  font-size: 3em;
}
.popup__bonuses {
  grid-template-columns: max-content max-content;
  margin-bottom: 5.6em;
}
.game__hidden {
  display: none;
}
.cards {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 2em;
  margin: 0 auto 46rem;
  width: max-content;
}
.card {
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.card__disabled {
  pointer-events: none;
}
.card:hover {
  filter: drop-shadow(0 0 2em #ffbbfc);
}
.card__normal {
  width: 42em;
  height: 26.2em;
  transition: all 0.3s ease-in-out;
}
.card__sum {
  position: absolute;
  width: 35.4em;
  height: 20.3em;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.card__final {
  position: absolute;
  width: 42em;
  height: 26.2em;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 4;
}
.card__action {
  position: absolute;
  width: 42em;
  height: 26.2em;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.card__win {
  pointer-events: none;
  filter: drop-shadow(0 0 2em #ffbbfc);
}
.card__win .card__normal {
  opacity: 0;
}
.card__win .card__final {
  opacity: 1;
}
.card__win .card__sum {
  opacity: 1;
}
.card__win .card__bonus {
  opacity: 1;
  transform: scale(1);
  transition: none;
}
.card__animation {
  pointer-events: none;
  filter: drop-shadow(0 0 2em #ffbbfc);
}
.card__animation .card__normal {
  opacity: 0;
}
.card__animation .card__action,
.card__animation .card__sum {
  opacity: 1;
}
.card__animation .card__bonus {
  opacity: 1;
  animation: shake 1s ease-in-out;
}
.card__bonus {
  width: 13.2em;
  height: 13.4em;
  position: absolute;
  right: -7%;
  top: -10%;
  opacity: 0;
  z-index: 5;
  transition: all 0.5s ease-in-out 0.2s;
  background: url(../assets/multi-bg.png) 50% 0 no-repeat;
  background-size: contain;
  text-align: center;
}
.card__bonus span {
  position: relative;
  font-weight: 900;
  left: 50%;
  text-transform: uppercase;
  transform: translateX(-50%);
  top: 2.5rem;
  font-size: 4.9em;
  line-height: 1.5;
  color: #f7e936;
  text-shadow: 0 -0.3rem 0 #8c1c75, 0 -0.3rem 0 #8c1c75, 0 0.3rem 0 #8c1c75,
    0 0.3rem 0 #8c1c75, -0.3rem 0 0 #8c1c75, 0.3rem 0 0 #8c1c75,
    -0.3rem 0 0 #8c1c75, 0.3rem 0 0 #8c1c75, -0.1rem -0.3rem 0 #8c1c75,
    0.1rem -0.3rem 0 #8c1c75, -0.1rem 0.3rem 0 #8c1c75, 0.1rem 0.3rem 0 #8c1c75,
    -0.3rem -0.1rem 0 #8c1c75, 0.3rem -0.1rem 0 #8c1c75,
    -0.3rem 0.1rem 0 #8c1c75, 0.3rem 0.1rem 0 #8c1c75, -0.2rem -0.3rem 0 #8c1c75,
    0.2rem -0.3rem 0 #8c1c75, -0.2rem 0.3rem 0 #8c1c75, 0.2rem 0.3rem 0 #8c1c75,
    -0.3rem -0.2rem 0 #8c1c75, 0.3rem -0.2rem 0 #8c1c75,
    -0.3rem 0.2rem 0 #8c1c75, 0.3rem 0.2rem 0 #8c1c75, -0.3rem -0.3rem 0 #8c1c75,
    0.3rem -0.3rem 0 #8c1c75, -0.3rem 0.3rem 0 #8c1c75, 0.3rem 0.3rem 0 #8c1c75,
    -0.3rem -0.3rem 0 #8c1c75, 0.3rem -0.3rem 0 #8c1c75,
    -0.3rem 0.3rem 0 #8c1c75, 0.3rem 0.3rem 0 #8c1c75;
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0) scale(0);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg) scale(1);
  }
  20% {
    transform: translate(-3px, 0) rotate(1deg) scale(1);
  }
  30% {
    transform: translate(3px, 2px) rotate(0) scale(1);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg) scale(1);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg) scale(1);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0) scale(1);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg) scale(1);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg) scale(1);
  }
  90% {
    transform: translate(1px, 2px) rotate(0) scale(1);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg) scale(1);
  }
}
@media (max-width: 1680px) {
  html {
    font-size: 9px;
  }
}
@media (max-width: 1560px) {
  html {
    font-size: 8.5px;
  }
  .popup {
    font-size: 90%;
  }
}
@media (max-width: 1450px) {
  html {
    font-size: 8px;
  }
}
@media (max-width: 1380px) {
  html {
    font-size: 7.75px;
  }
}
@media (max-width: 1200px) {
  html {
    font-size: 7.5px;
  }
  .bonuses__page {
    font-size: 90%;
    transform: translateX(0);
  }
}
@media (max-width: 1025px) {
  html {
    font-size: 7.25px;
  }
}
@media (max-width: 992px) {
  html {
    font-size: 7px;
  }
  .cards {
    font-size: 90%;
  }
  .bonuses__page {
    left: 2rem;
  }
}
@media (max-width: 880px) {
  html {
    font-size: 6.5px;
  }
}
@media (max-width: 780px) {
  .cards {
    font-size: 85%;
  }
}
@media (max-width: 750px) {
  html {
    font-size: 6px;
  }
  .lang_switcher_outer {
    font-size: 120%;
  }
}
@media (max-width: 680px) {
  html {
    font-size: 5.5px;
  }
}
@media (max-width: 620px) {
  html {
    font-size: 7.25px;
  }
  .wrapper {
    min-height: 100vh;
  }
  .logo {
    max-width: 48rem;
    min-width: 42rem;
  }
  .cards {
    grid-template-columns: 1fr;
    font-size: 88%;
    margin-bottom: 30rem;
  }
  .title {
    margin-bottom: 9rem;
  }
  .title__second {
    margin-bottom: 6rem;
  }
  .bonuses__page {
    top: 34rem;
  }
  .title__main {
    font-size: 6.2rem;
  }
  .pl .title__main_first {
    font-size: 5.8rem;
    white-space: nowrap;
  }
  .title__additional {
    font-size: 2.8rem;
  }
  .wheel {
    font-size: 115%;
    margin-bottom: 40rem;
  }
  .footer {
    opacity: 1;
  }
  .footer__text {
    opacity: 0.5;
  }
  .payments__desk {
    display: none;
  }
  .payments__mob {
    display: block;
  }
  .lang_switcher_outer p {
    display: none;
  }
  .lang_switcher_outer {
    width: 8.5em;
    font-size: 130%;
  }
  .lang_switcher {
    padding: 0 0.5em;
  }
  .popup {
    font-size: 95%;
  }
  .popup__window {
    top: 17rem;
  }
}
@media (max-width: 550px) {
  html {
    font-size: 6.75px;
  }
  .bonuses__page {
    font-size: 85%;
    left: 1rem;
  }
}
@media (max-width: 480px) {
  html {
    font-size: 6.5px;
  }
}
@media (max-width: 450px) {
  html {
    font-size: 6px;
  }
  .cards {
    font-size: 85%;
  }
}
@media (max-width: 410px) {
  html {
    font-size: 5.5px;
  }
}
@media (max-width: 370px) {
  html {
    font-size: 5.25px;
  }
}
@media (max-width: 350px) {
  html {
    font-size: 4.75px;
  }
}
@media (max-width: 330px) {
  html {
    font-size: 4.5px;
  }
}

@keyframes slideTop {
  20% {
    top: 100%;
  }

  40% {
    top: 80%;
  }

  60% {
    top: 70%;
  }

  80% {
    top: 60%;
  }
  100% {
    top: 5%;
  }
}

#notifications_popup {
  font-size: 16px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9000000000;

  &.active {
    opacity: 1;
    visibility: visible;
    .wrap {
      top: 0;
    }
  }
  .wrap {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 46rem;
    backdrop-filter: blur(12px);
    border-radius: 0.8rem;
    margin: 1.9rem;
    padding-top: 2rem;
    transition: all 0.3s ease;
    top: 4rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: #191919;
    color: #e2e2e2;
  }
  .text {
    padding: 0 2rem;
    p {
      margin: 1rem 0 0.8rem;
      font-size: 1.8rem;
    }
  }
  .bottom {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    a {
      align-self: center;
      padding: 2.4rem 0;
      font-size: 2rem;
    }
  }
}
.z-10000 {
  z-index: 999999;
  position: relative;
}
</style>
